var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.element.hidden || _vm.showHidden)?_c('div',{attrs:{"id":_vm._htmlSafe(((_vm.onSidePanel ? 'sp' : 'kb') + "-" + _vm.legoPath + "-" + _vm.elementPath))}},[(_vm._pytruthy(_vm.element.groupHeader))?_c('div',{staticClass:"tw-font-bold tw-mb-4",class:_vm.isMobile ? 'tw-text-base' : 'tw-text-xl'},[_vm._v(" "+_vm._s(_vm.element.groupHeader)+" ")]):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col",class:_vm.element.jsonType === 'array' && _vm._pyfalsey(_vm.element.listElements)
        ? 'tw-gap-2'
        : 'tw-gap-4'},[(
        !(
          (_vm.element.jsonType === 'object' && _vm.element.key === '') ||
          (_vm.flatView && _vm.element.jsonType === 'object')
        )
      )?_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-x-5 tw-items-start tw-flex-grow tw-gap-y-4 lego-param"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-4",class:[
          (_vm.element.inputType === 'text' &&
            (_vm.element.constraints.maxLength > 24 ||
              (_vm.element.constraints.maxLength == null &&
                _vm.element.value &&
                _vm.element.value.length > 24))) ||
          _vm.element.inputType == 'textarea'
            ? 'tw-flex-auto'
            : '',
          ,
          _vm.element.jsonType === 'object' || _vm.element.jsonType === 'array'
            ? 'tw-items-start'
            : 'tw-items-center' ]},[(_vm.hasDropdownParamSender && _vm.senderHasSucceeded)?[_c('ParamDropdown',{staticClass:"highlight-input tw-min-w-full",attrs:{"value":_vm._elementIsEmpty(_vm.element) &&
              _vm.element.defaultAmbiguousInput !== null
                ? 'flankDefaultAmbiguousInput'
                : _vm.element.value,"keyName":_vm.keyOrDisplay,"valueColField":_vm.element.paramSender.references.valueColField,"displayColField":_vm.element.paramSender.references.displayColField,"senderTransformedData":_vm.senderRunWrapper.transformedData,"valueIsPopulated":_vm.valueIsPopulated,"disabled":_vm.element.lockDefaultValue,"multiselect":_vm.element.paramSender.uiType === 'multiselect',"placeholder":_vm.element.placeholder,"tooltip":_vm.element.tooltip,"hidden":_vm.element.hidden,"note":_vm.element.note,"defaultAmbiguousInput":_vm.element.defaultAmbiguousInput},on:{"input":function ($event) {
                if ($event === 'flankDefaultAmbiguousInput') {
                  _vm.clearElementValueAndValidity(_vm.element);
                } else if ($event === undefined) {
                  // this case happens when ambiguou is selected
                  // but then the user makes the field required
                  _vm.clearElementValueAndValidity(_vm.element);
                } else {
                  _vm.setElementParamSender({ value: $event });
                }
              }}})]:(
            _vm.element.constraints.enum &&
            _vm.element.constraints.enum.length > 0 &&
            _vm.element.component === 'ParamMultiselect'
          )?_c('ParamMultiselect',{style:(_vm.componentWidth),attrs:{"valueIsPopulated":_vm.valueIsPopulated,"disabled":_vm.element.lockDefaultValue,"value":_vm.listElementValues,"keyName":_vm.keyOrDisplay,"inputOptions":_vm.element.constraints.enum,"element":_vm.element,"componentWidth":_vm.componentWidth,"isMobile":_vm.isMobile},on:{"input":function($event){return _vm.setElementParamSender({ value: $event })},"unlockDefaultValue":_vm.unlockDefaultValue}}):(
            _vm.element.constraints.enum &&
            _vm.element.constraints.enum.length > 0 &&
            _vm.element.component !== 'ParamMultiselect'
          )?_c('b-field',{class:_vm.isMobile ? 'tw-col-span-5' : '',style:(_vm.componentWidth),attrs:{"label-position":"","label":_vm.keyOrDisplay,"custom-class":"tw-font-normal tw-text-gray-500"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('span',{staticClass:"tw-text-sm tw-text-gray-500"},[_vm._v(_vm._s(_vm.keyOrDisplay))]),(_vm.element.defaultAmbiguousInput === null)?_c('span',{staticClass:"tw-text-red-500 tw-pl-1"},[_vm._v("*")]):_vm._e(),(_vm.element.tooltip && _vm.element.tooltip !== '' && !_vm.isMobile)?_c('b-tooltip',{attrs:{"type":"is-primary is-light","position":"is-right","label":_vm.element.tooltip}},[_c('b-icon',{staticClass:"tw-ml-1 tw-text-purple-300",attrs:{"icon":"info-circle","size":"is-small"}})],1):_vm._e(),(_vm.element.hidden && !_vm.isMobile)?_c('b-tooltip',{attrs:{"type":" is-light","position":"is-right","label":"Hidden Input"}},[(_vm.element.hidden && !_vm.isMobile)?_c('b-icon',{staticClass:"tw-text-gray-400 tw-ml-1",attrs:{"size":"is-small","icon":"eye-slash"}}):_vm._e()],1):_vm._e(),_c('b-tooltip',{attrs:{"label":"Click to unlock default value","type":"is-light","position":"is-right"}},[_c('div',{staticClass:"hover:tw-cursor-pointer",on:{"mouseover":function($event){_vm.hovered = true},"mouseout":function($event){_vm.hovered = false},"click":_vm.unlockDefaultValue}},[(_vm.element.lockDefaultValue && !_vm.isMobile)?_c('b-icon',{staticClass:"tw-text-gray-400 hover:tw-text-purple-500 tw-ml-1",attrs:{"icon":_vm.hovered ? 'unlock' : 'lock',"size":"is-small"}}):_vm._e()],1)]),_c('div',{staticClass:"tw-mt-1 tw-text-xs tw-text-gray-500"},[(_vm.element.note !== '' && !_vm.isMobile)?_c('MarkdownDisplay',{attrs:{"markdown":_vm.element.note}}):_vm._e()],1)],1)]},proxy:true}],null,false,2417532346)},[_c('b-select',{staticClass:"highlight-input",style:(_vm.componentWidth),attrs:{"disabled":_vm.element.lockDefaultValue,"expanded":true,"value":_vm._elementIsEmpty(_vm.element) &&
              _vm.element.defaultAmbiguousInput !== null
                ? 'flankDefaultAmbiguousInput'
                : _vm.element.value,"placeholder":_vm.element.placeholder},on:{"input":function ($event) {
                if ($event === 'flankDefaultAmbiguousInput') {
                  _vm.clearElementValueAndValidity(_vm.element);
                } else if ($event === undefined) {
                  // this case happens when ambiguou is selected
                  // but then the user makes the field required
                  _vm.clearElementValueAndValidity(_vm.element);
                } else {
                  _vm.setElement({ value: $event });
                }
              }}},[(_vm.element.defaultAmbiguousInput !== null)?_c('option',{attrs:{"value":"flankDefaultAmbiguousInput"}},[_vm._v(" "+_vm._s(_vm.getDefaultAmbiguousInputDisplay(_vm.element.defaultAmbiguousInput))+" ")]):_vm._e(),_vm._l((_vm.element.constraints.enum),function(val){return _c('option',{key:val,domProps:{"value":val}},[_vm._v(" "+_vm._s(val)+" ")])})],2)],1):_c('div',{class:[
            (_vm.element.inputType === 'text' &&
              (_vm.element.constraints.maxLength > 24 ||
                (_vm.element.constraints.maxLength == null &&
                  _vm.element.value &&
                  _vm.element.value.length > 24))) ||
            _vm.element.inputType == 'textarea'
              ? 'tw-w-full tw-max-w-3xl'
              : 'tw-w-72' ],staticStyle:{"min-width":"8rem"},style:((_vm.element.inputType == 'text' ||
              _vm.element.inputType == 'textarea') &&
            _vm.element.constraints.maxLength !== null
              ? 'margin-bottom: -22px;'
              : '')},[(
              _vm.element.jsonType === 'object' ||
              (_vm.element.jsonType === 'array' &&
                _vm.element.listElementSchema.jsonType !== 'string')
            )?_c('div',{staticClass:"tw-font-bold tw-text-gray-700"},[_vm._v(" "+_vm._s(_vm.keyOrDisplay)+" ")]):[_c('b-field',{class:_vm.isMobile ? 'tw-col-span-5' : '',style:(_vm.componentWidth),attrs:{"label-position":"","custom-class":"tw-font-normal tw-text-gray-500"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('span',{staticClass:"tw-text-sm tw-text-gray-500"},[_vm._v(_vm._s(_vm.keyOrDisplay))]),(_vm.element.defaultAmbiguousInput === null)?_c('span',{staticClass:"tw-text-red-500 tw-pl-1"},[_vm._v("*")]):_vm._e(),(
                      _vm.element.tooltip && _vm.element.tooltip !== '' && !_vm.isMobile
                    )?_c('b-tooltip',{attrs:{"type":"is-primary is-light","position":"is-right","label":_vm.element.tooltip}},[_c('b-icon',{staticClass:"tw-ml-1 tw-text-purple-300",attrs:{"icon":"info-circle","size":"is-small"}})],1):_vm._e(),(_vm.element.hidden && !_vm.isMobile)?_c('b-tooltip',{attrs:{"type":" is-light","position":"is-right","label":"Hidden Input"}},[(_vm.element.hidden && !_vm.isMobile)?_c('b-icon',{staticClass:"tw-text-gray-400 tw-ml-1",attrs:{"size":"is-small","icon":"eye-slash"}}):_vm._e()],1):_vm._e(),_c('b-tooltip',{staticClass:"tw-ml-1",attrs:{"label":"Click to unlock default value","type":"is-light","position":"is-right"}},[_c('div',{staticClass:"hover:tw-cursor-pointer",on:{"mouseover":function($event){_vm.hovered = true},"mouseout":function($event){_vm.hovered = false},"click":_vm.unlockDefaultValue}},[(_vm.element.lockDefaultValue && !_vm.isMobile)?_c('b-icon',{staticClass:"tw-text-gray-400 hover:tw-text-purple-500",attrs:{"icon":_vm.hovered ? 'unlock' : 'lock',"size":"is-small"}}):_vm._e()],1)]),_c('div',{staticClass:"tw-text-xs tw-mt-1 tw-text-gray-500"},[(_vm.element.note !== '' && !_vm.isMobile)?_c('MarkdownDisplay',{attrs:{"markdown":_vm.element.note}}):_vm._e()],1)],1)]},proxy:true}],null,false,4106711962)},[(
                  _vm.element.component === 'b-input' ||
                  (_vm._pyfalsey(_vm.element.component) &&
                    _vm.element.jsonType !== 'array')
                )?_c('b-input',{ref:"input",staticClass:"highlight-input",attrs:{"icon":_vm.element.inputType === 'number' ? 'hashtag' : '',"type":_vm.getInputType(_vm.element),"step":_vm.element.constraints.step,"min":_vm.element.constraints.minimum < -900719925472 &&
                  _vm.element.constraints.step !== null
                    ? null
                    : _vm.element.constraints.minimum,"max":_vm.element.constraints.maximum > 900719925472 &&
                  _vm.element.constraints.step !== null
                    ? null
                    : _vm.element.constraints.maximum,"maxlength":_vm.element.constraints.maxLength,"placeholder":_vm.element.placeholder,"pattern":_vm.element.constraints.pattern,"validation-message":_vm.element.constraints.validationMessage,"disabled":_vm.element.lockDefaultValue ||
                  (_vm.element.paramSender !== null &&
                    (!_vm.senderHasSucceeded ||
                      _vm.element.paramSender.references.valueColField !== '') &&
                    !_vm.onRunForEachSidePanel),"value":_vm.element.value},on:{"input":function($event){return _vm.setElement({ value: $event })},"blur":_vm.updateValidity}}):(
                  _vm.element.component == 'b-checkbox' &&
                  _vm.element.defaultAmbiguousInput === null
                )?_c('b-checkbox',{staticClass:"tw-text-black highlight-input",attrs:{"disabled":_vm.element.lockDefaultValue ||
                  (_vm.element.paramSender !== null && !_vm.senderHasSucceeded),"value":_vm.element.value},on:{"input":function($event){return _vm.setElement({ value: $event })}}},[_vm._v(_vm._s(_vm.keyOrDisplay))]):(
                  _vm.element.component == 'b-checkbox' &&
                  _vm.element.defaultAmbiguousInput !== null
                )?_c('b-select',{attrs:{"value":_vm.element.value === null ? '' : _vm.element.value.toString()},on:{"input":function($event){return _vm.setThreewayBit({ value: $event })}}},[_c('option',{attrs:{"value":"true"}},[_vm._v("True")]),_c('option',{attrs:{"value":"false"}},[_vm._v("False")]),_c('option',{attrs:{"value":"default"}},[_vm._v(" "+_vm._s(_vm.element.defaultAmbiguousInput === "explicitNull" ? "NULL" : _vm.element.defaultAmbiguousInput === "omit" ? "Omit" : "Empty")+" ")])]):(_vm.element.component == 'b-datepicker')?_c('TimsDatepicker',{staticClass:"highlight-input",attrs:{"value":_vm.element.value,"max-date":_vm.element.constraints.maximum
                    ? new Date(_vm.element.constraints.maximum)
                    : null,"min-date":_vm.element.constraints.minimum
                    ? new Date(_vm.element.constraints.minimum)
                    : null,"disabled":_vm.element.lockDefaultValue,"placeholder":_vm.element.placeholder},on:{"input":function($event){return _vm.setElement({ value: $event })},"icon-right-click":function($event){return _vm.setElement({ value: null })}}}):(_vm.element.component == 'b-datetimepicker')?_c('TimsDatetimepicker',{staticClass:"highlight-input",attrs:{"value":_vm.element.value,"max-datetime":_vm.element.constraints.maximum
                    ? new Date(_vm.element.constraints.maximum)
                    : null,"min-datetime":_vm.element.constraints.minimum
                    ? new Date(_vm.element.constraints.minimum)
                    : null,"disabled":_vm.element.lockDefaultValue,"placeholder":_vm.element.placeholder,"icon-right-clickable":""},on:{"input":function($event){return _vm.setElement({ value: $event })},"icon-right-click":function($event){return _vm.setElement({ value: null })}}}):(_vm.element.component == 'b-timepicker')?_c('b-timepicker',{attrs:{"value":_vm.element.value,"editable":"","disabled":_vm.element.lockDefaultValue,"icon":"clock","enable-seconds":"","placeholder":_vm.element.placeholder,"hour-format":"12"},on:{"input":function($event){return _vm.setElement({ value: $event })}}},[_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-x-3"},[_c('b-button',{attrs:{"label":"Now","custom-class":"tw-text-gray-500","icon-left":"clock"},on:{"click":function($event){_vm.setElement({ value: new Date() })}}}),_c('b-button',{staticClass:"tw-text-gray-500",attrs:{"label":"Clear","icon-left":"times-circle","outlined":""},on:{"click":function($event){return _vm.setElement({ value: null })}}})],1)]):(
                  _vm.element.jsonType === 'array' &&
                  !_vm.hasDropdownParamSender &&
                  _vm.element.component !== 'ParamMultiselect' &&
                  _vm.element.listElementSchema.jsonType === 'string'
                )?_c('b-taginput',{style:(_vm.componentWidth),attrs:{"value":_vm.listElementValues,"disabled":_vm.element.lockDefaultValue,"placeholder":_vm.element.placeholder,"confirm-keys":_vm.isLocationsTagInput
                    ? [';', 'Tab', 'Enter']
                    : [',', 'Tab', 'Enter'],"on-paste-separators":_vm.isLocationsTagInput ? [';'] : [',']},on:{"add":function($event){return _vm.addElementList(false, true, [$event])},"remove":function($event){return _vm.removeListElementByValue($event)}}}):_vm._e()],1)]],2),(_vm.element.paramSender !== null && !_vm.isMobile && !_vm.onSidePanel)?[(!_vm.senderHasSucceeded)?_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-gap-x-2"},[(_vm.linkToThisElement)?_c('b-icon',{staticClass:"tw-text-gray-500",attrs:{"icon":"hourglass-start"}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.linkToThisElement ? ("Fill in " + _vm.paramSenderName) : ("Waiting on " + _vm.paramSenderName))+" ")])],1):_vm._e()]:_vm._e()],2)]):_vm._e(),(_vm.element.jsonType === 'object')?_c('div',[_c('div',{staticClass:"tw-flex flex-row"},[(!_vm.isArrayElement && !_vm.flatView)?_c('div',[_c('div',{staticClass:"tw-w-2 tw-ml-1 tw-h-full tw-mr-6",class:[
              _vm.depth === 0 ? 'tw-bg-gray-100' : '',
              _vm.depth === 1 ? 'tw-bg-gray-200' : '',
              _vm.depth === 2 ? 'tw-bg-gray-300' : '',
              _vm.depth === 3 ? 'tw-bg-gray-400' : '',
              _vm.depth === 4 ? 'tw-bg-gray-500' : '',
              _vm.depth === 5 ? 'tw-bg-gray-600' : '',
              _vm.depth >= 6 ? 'tw-bg-gray-700' : '' ]})]):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-6"},_vm._l((_vm.element.objectPropertySchemas),function(e){return _c('LegoParam',{key:e.uuid,class:_vm.isMobile == true ? '' : 'tw-mr-8',attrs:{"propElement":e,"legoPath":_vm.legoPath,"kitDoll":_vm.kitDoll,"parentJsonType":_vm.element.jsonType,"depth":_vm.depth + 1,"arrayDepth":_vm.arrayDepth,"isMobile":_vm.isMobile,"onSidePanel":_vm.onSidePanel,"cannotEditKit":_vm.cannotEditKit,"showHidden":_vm.showHidden,"pathToHere":_vm.elementPath,"mostRecentRunsPath":_vm.mostRecentRunsPath,"dropdownResults":_vm.dropdownResults,"isCopycatKit":_vm.isCopycatKit,"newParamSenderPath":_vm.newParamSenderPath,"flatView":_vm.flatView,"viewingPublicly":_vm.viewingPublicly},on:{"emitKitDollUpdate":function($event){return _vm.$emit('emitKitDollUpdate', $event)},"emitRemoveCSSTransform":function($event){return _vm.$emit('emitRemoveCSSTransform')},"emitShowReceiveSidePanel":function($event){return _vm.$emit('emitShowReceiveSidePanel', $event)}}})}),1)])]):(
        _vm.element.jsonType === 'array' &&
        !_vm.hasDropdownParamSender &&
        _vm.element.component !== 'ParamMultiselect' &&
        _vm.element.listElementSchema.jsonType !== 'string'
      )?[_vm._l((_vm.element.listElements),function(le,i){return _c('div',{key:le.uuid,class:_vm.isMobile == true ? '' : 'tw-mr-8'},[_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-gap-x-5"},[_c('div',{staticClass:"tw-border-2 tw-rounded-lg tw-flex tw-flex-row",class:[
              _vm.arrayDepth <= 1 ? 'tw-bg-gray-50 tw-border-gray-200' : '',
              _vm.arrayDepth === 2 ? 'tw-bg-gray-100 tw-border-gray-300' : '',
              _vm.arrayDepth === 3 ? 'tw-bg-gray-200 tw-border-gray-400' : '',
              _vm.arrayDepth === 4 ? 'tw-bg-gray-300 tw-border-gray-500' : '',
              _vm.arrayDepth === 5 ? 'tw-bg-gray-400 tw-border-gray-600' : '',
              _vm.arrayDepth === 6 ? 'tw-bg-gray-500 tw-border-gray-700' : '',
              _vm.arrayDepth >= 7 ? 'tw-bg-gray-600 tw-border-gray-800' : '' ]},[_c('LegoParam',{staticClass:"tw-p-6",attrs:{"propElement":le,"parentJsonType":_vm.element.jsonType,"elementIndex":i,"legoPath":_vm.legoPath,"kitDoll":_vm.kitDoll,"depth":_vm.depth + 1,"arrayDepth":_vm.arrayDepth + 1,"isMobile":_vm.isMobile,"onSidePanel":_vm.onSidePanel,"cannotEditKit":_vm.cannotEditKit,"isCopycatKit":_vm.isCopycatKit,"showHidden":_vm.showHidden,"pathToHere":_vm.elementPath,"mostRecentRunsPath":_vm.mostRecentRunsPath,"dropdownResults":_vm.dropdownResults,"newParamSenderPath":_vm.newParamSenderPath,"flatView":_vm.flatView,"viewingPublicly":_vm.viewingPublicly},on:{"emitKitDollUpdate":function($event){return _vm.$emit('emitKitDollUpdate', $event)},"emitRemoveCSSTransform":function($event){return _vm.$emit('emitRemoveCSSTransform')},"emitShowReceiveSidePanel":function($event){return _vm.$emit('emitShowReceiveSidePanel', $event)}}}),_c('div',{staticClass:"tw-self-end tw-pr-2 tw-text-gray-500"},[_vm._v(_vm._s(i))])],1),_c('a',{on:{"click":function($event){return _vm.removeListElementByIndex(i)}}},[_c('b-icon',{staticClass:"tw-text-gray-400",attrs:{"icon":"trash-alt"}})],1)])])}),_c('div',[_c('b-button',{attrs:{"icon-left":"plus","type":"is-light"},on:{"click":function($event){return _vm.addElementList(false, false, [])}}})],1)]:_vm._e()],2),(_vm.noteSidePanelOpen)?_c('ParamNoteSidePanel',{attrs:{"element":_vm.element},on:{"close":_vm.closeNoteSidePanel,"save":_vm.saveNoteAndTooltip}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }